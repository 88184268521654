import React from 'react';
import classNames from 'classnames';
import style from './footer.module.scss';

interface Props {
  isMoblie: boolean;
  isEn: boolean;
  copyright: Record<string, string>;
}

export const CopyRight = (props: Props) => {
  const { copyright, isMoblie, isEn } = props;

  const fullyear = `${new Date().getFullYear()}`;
  return (
    <div className={classNames(style.copyright, isEn ? style.copyrightEn : '')}>
      <div className={style.copyrightWrap}>
        <div className={classNames(style.copyrightLeft, 'hidden-es')}>
          <p>
            {copyright.jubao}
            <span className="hidden xl:inline">，</span>
          </p>
          <p>{copyright.shenming}</p>
        </div>
        <div className={style.copyrightRight}>
          <div className="text-center lg:text-left">
            <span className="block lg:mr-2 lg:inline">
              <span className={classNames(style.linkPadding)}>{copyright?.copyright?.replace('$year$', fullyear)}</span>
            </span>
            <span className="block lg:inline">
              <a
                href="https://beian.miit.gov.cn/"
                className={classNames(style.footerLink, style.underlinelink, style.linkPadding)}
                target="_blank"
                rel="noreferrer"
              >
                {copyright.icp}
              </a>
              <a
                className={classNames(style.footerLink, style.underlinelink, style.icpIcon, style.linkPadding)}
                href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11000002002052"
                target="_blank"
                rel="noreferrer"
              >
                <span className={style.underlinelink}>{copyright.remark}</span>
              </a>
            </span>
            <span className="block lg:inline">
              <a
                className={classNames(
                  style.lion,
                  style.footerLink,
                  style.underlinelink,
                  style.linkPadding,
                  'mr-2 lg:mr-0',
                )}
                href={
                  isMoblie
                    ? 'https://rules-center.meituan.com/m/detail/guize/2'
                    : 'https://rules-center.meituan.com/rules-detail/2'
                }
                rel="noreferrer"
                target="_blank"
              >
                {copyright.privacy}
              </a>
              <a
                className={classNames(style.lion, style.footerLink, style.underlinelink, style.linkPadding)}
                href="https://rules-center.meituan.com/"
                rel="noreferrer"
                target="_blank"
              >
                {copyright.rules}
              </a>
            </span>
          </div>
        </div>
        {copyright?.medicalCare && (
          <div className={classNames(style.copyrightBottom, 'text-center lg:text-center')}>
            <span>{copyright.medicalCare}</span>
          </div>
        )}
      </div>
    </div>
  );
};
